import { useLocation } from "react-router-dom";

import { DocumentRequirementType } from "../../Documents";

export function useDocumentDetailsUrlParams() {
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const requirementId = params.get("requirement") ?? undefined;
  const requirementTypeString = params.get("requirementStatus") ?? undefined;
  const facilityId = params.get("hcfId") ?? undefined;

  // Either set if it matches a value in the enum or set to undefined
  const requirementType = Object.values(DocumentRequirementType).includes(
    requirementTypeString as DocumentRequirementType
  )
    ? (requirementTypeString as DocumentRequirementType)
    : undefined;

  return {
    requirementId,
    requirementType,
    facilityId,
  };
}

import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";

import { DocumentUiVariant, type HcpRequirement } from "../../Documents/types";
import { useCreateDocument } from "../api/useCreateDocument";
import { useCreateStripeVerificationSession } from "../api/useCreateStripeVerificationSession";

interface UseUploadAndGetVerificationSessionProps {
  hcpId: string;
  selectedRequirement: HcpRequirement;
  personalIdSubtype: string;
}

/*
  These are placeholder values that we use to create a personal ID document to our backend
  while the user would upload the document through stripe identity
  Read more here: https://clipboardhealth.atlassian.net/wiki/spaces/EPP/pages/2551054487/Product+Brief+-+automating+ID+verification
  Link to a discussion on this: https://github.com/ClipboardHealth/cbh-mobile-app/pull/2452#discussion_r1333276671
*/
/**
 * @deprecated This placeholder value is deprecated and will be removed when we
 * change the personal ID flow to not use dummy documents
 */
const DRIVING_LICENSE_STATE_ID_PLACEHOLDER_FILE_NAME = "driver_s_license_or_state_id";
/**
 * @deprecated This placeholder value is deprecated and will be removed when we
 * change the personal ID flow to not use dummy documents
 */
const DRIVING_LICENSE_STATE_ID_PLACEHOLDER_ROOT_KEY = "document-storage-service-cbh-dev";
/**
 * @deprecated This placeholder value is deprecated and will be removed when we
 * change the personal ID flow to not use dummy documents
 *
 * Note: This file and url is visible to the user
 */
const DRIVING_LICENSE_STATE_ID_PLACEHOLDER_FILE_KEY =
  "635a84f62ebfd88fddceb269/5baabbd23b089e0004880e55/a2de0370-3dc1-4a54-9046-cf2c8ef59e76.jpeg";

/**
 * @deprecated This hook is deprecated and will be removed when we
 * change the personal ID flow to not use dummy documents
 */
export function useUploadAndGetVerificationSession() {
  const {
    mutateAsync: createStripeVerificationSession,
    isLoading: isLoadingStripeVerificationSession,
  } = useCreateStripeVerificationSession();
  const { mutateAsync: createDocument, isLoading: isLoadingCreateDocument } = useCreateDocument();

  const uploadAndGetVerificationSession = async (
    props: UseUploadAndGetVerificationSessionProps
  ) => {
    const { hcpId, selectedRequirement, personalIdSubtype } = props;

    let newDocument;

    try {
      newDocument = await createDocument({
        hcpId,
        name: DRIVING_LICENSE_STATE_ID_PLACEHOLDER_FILE_NAME,
        fileStorageFileKey: DRIVING_LICENSE_STATE_ID_PLACEHOLDER_ROOT_KEY,
        fileStorageRootFolder: DRIVING_LICENSE_STATE_ID_PLACEHOLDER_FILE_KEY,
        subType: personalIdSubtype,
        uploadedFor: selectedRequirement.reqId,
      });
    } catch (error) {
      logEvent(APP_V2_USER_EVENTS.UPLOAD_DOCUMENT_FAILURE, {
        ui_variant: DocumentUiVariant.V2,
      });
      throw error;
    }

    logEvent(APP_V2_USER_EVENTS.UPLOAD_DOCUMENT_SUCCESS, {
      ui_variant: DocumentUiVariant.V2,
    });

    const documentId = newDocument.data.createHcpDocument._id;

    const response = await createStripeVerificationSession({
      hcpId,
      documentId,
    });

    const { clientSecret, verificationSessionId, ephemeralKeySecret } = response;

    return {
      documentId,
      clientSecret,
      verificationSessionId,
      ephemeralKeySecret,
    };
  };

  return {
    uploadAndGetVerificationSession,
    isLoadingStripeVerificationSession:
      isLoadingStripeVerificationSession || isLoadingCreateDocument,
  };
}

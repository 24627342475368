import { type CameraSource } from "@capacitor/camera";
import {
  type AcceptableUploadFileContentTypes,
  type AcceptableUploadImageContentTypes,
} from "@src/appV2/lib/files";
import { z } from "zod";

export interface UploadImageFileData {
  dataUrlBase64: string;
  extension: string;
  blob: Blob;
  contentType: AcceptableUploadImageContentTypes;
}

export interface UploadFileData {
  dataUrlBase64: string;
  extension: string;
  blob: Blob;
  contentType: AcceptableUploadFileContentTypes;
}

export enum ExpiryType {
  DOES_NOT_EXPIRE = "DOES_NOT_EXPIRE",
  EXPIRY_DATE = "EXPIRY_DATE",
  ISSUE_PLUS_VALIDITY = "ISSUE_PLUS_VALIDITY",
}

export type AllowedCameraSourceType = CameraSource.Camera | CameraSource.Photos;

export enum FileSelectionType {
  PDF,
  IMAGE,
}

export enum RequirementStatus {
  PENDING = "pending",
  APPROVED = "approved",
  SOON_TO_EXPIRE = "soon_to_expire",
  REJECTED = "rejected",
  EXPIRED = "expired",
}

const professionalReferenceSchema = z.object({
  employerName: z.string(),
  referenceFirstName: z.string(),
  referenceLastName: z.string(),
  fromMonth: z.number(),
  toMonth: z.number(),
  fromYear: z.number(),
  toYear: z.number(),
  workerTitle: z.string(),
  userId: z.string(),
  status: z.string(),
  _id: z.string(),
});

const professionalReferenceInviteMessageSchema = z.object({
  messageText: z.string(),
  webFormLink: z.string(),
});

export const professionalReferenceResponseSchema = z.object({
  inviteMessage: professionalReferenceInviteMessageSchema.optional(),
  references: z.array(professionalReferenceSchema),
  completed: z.boolean(),
  minVerifiedReferenceRequired: z.number(),
});

export type ProfessionalReferenceResponse = z.infer<typeof professionalReferenceResponseSchema>;

export enum ReferenceVariants {
  V1_MANUAL = "v1_manual",
  V2_AUTOMATED = "v2_automated",
}

export enum ProfessionalReferenceStatus {
  REQUESTED = "requested",
  DECLINED = "declined",
  VERIFIED = "verified",
}

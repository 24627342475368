import { Browser } from "@capacitor/browser";
import { useToast } from "@src/appV2/lib";

import { useDocumentDistributionUrl } from "../api/useDocumentDistributionUrl";

export function useDocumentPreview() {
  const { showErrorToast } = useToast();

  const { mutateAsync, isLoading } = useDocumentDistributionUrl();

  const onClick = async (documentId: string): Promise<void> => {
    try {
      const { distributionUrl } = await mutateAsync(documentId);
      await Browser.open({
        url: distributionUrl,
      });
    } catch {
      showErrorToast("File could not be opened!");
    }
  };

  return { onClick, isLoading };
}

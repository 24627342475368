import { type HcpRequirement } from "../../Documents/types";
import { useCreateDocument } from "../api/useCreateDocument";
import { useUploadFileToFileStorage } from "../api/useUploadFileToFileStorage";
import { type UploadFileData } from "../types";
import { getDocumentFileName } from "../utils";

interface UploadAndCreateDocumentProps {
  hcpId: string;
  file: UploadFileData;
  requirementId: string;
  selectedRequirement: HcpRequirement;
  personalIdSubtype?: string;
}

export function useUploadAndCreateDocument() {
  const { mutateAsync: uploadFileToFileStorage, isLoading: isLoadingUploadFileToFileStorage } =
    useUploadFileToFileStorage();
  const { mutateAsync: createDocument, isLoading: isLoadingCreateDocument } = useCreateDocument();

  const uploadAndCreateDocument = async (props: UploadAndCreateDocumentProps) => {
    const { hcpId, file, requirementId, selectedRequirement, personalIdSubtype } = props;

    const { fileStorageFileKey, fileStorageRootFolder } = await uploadFileToFileStorage({
      requirementId,
      file,
    });
    const fileName = fileStorageFileKey?.split("/").pop();
    await createDocument({
      hcpId,
      name: getDocumentFileName({ requirementName: selectedRequirement.name, fileName }),
      fileStorageFileKey,
      fileStorageRootFolder,
      subType: personalIdSubtype ?? "",
      uploadedFor: selectedRequirement.reqId,
    });
  };

  return {
    uploadAndCreateDocument,
    isLoading: isLoadingUploadFileToFileStorage || isLoadingCreateDocument,
  };
}

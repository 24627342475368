import { Filesystem } from "@capacitor/filesystem";
import { isDefined } from "@clipboard-health/util-ts";
import { Chooser } from "@ionic-native/chooser";
import { APP_V2_APP_EVENTS, isCapacitorPlatform } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import {
  type AcceptableUploadImageContentTypes,
  base64ToBlob,
  blobToBase64,
  getFileTypeFromBlob,
  isAcceptableFileUploadContentType,
} from "@src/appV2/lib/files";

import { type UploadFileData } from "../types";

interface MobileOnFileSelectProps {
  onSuccess: (file: UploadFileData) => void;
  onError: (error: Error) => void;
}

export function useMobileOnFileSelect(props: MobileOnFileSelectProps): () => Promise<void> {
  const { onSuccess, onError } = props;

  return async () => {
    try {
      if (!isCapacitorPlatform()) {
        throw new Error("Invalid Platform");
      }

      const result = await Chooser.getFile("image/jpeg,image/png,application/pdf");

      // This means the user has cancelled selection
      if (!isDefined(result)) {
        return;
      }

      const { uri, mediaType: contentType } = result;

      const fileContents = await Filesystem.readFile({
        path: uri,
      });

      const fileData =
        typeof fileContents.data === "string"
          ? fileContents.data
          : await blobToBase64(fileContents.data);
      const dataUrlBase64 = `data:${contentType};base64,${fileData}`;
      const blob = await base64ToBlob(dataUrlBase64);
      const { extension } = await getFileTypeFromBlob(blob);

      if (!isAcceptableFileUploadContentType(contentType)) {
        throw new Error(`Image with unsupported content type picked: ${contentType}`);
      }

      onSuccess({
        contentType: contentType as AcceptableUploadImageContentTypes,
        dataUrlBase64,
        extension,
        blob,
      });
    } catch (error) {
      logEvent(APP_V2_APP_EVENTS.DOCUMENT_UPLOAD_MOBILE_FILE_PICKER_FAILURE, {
        error,
      });
      onError(error as Error);
    }
  };
}

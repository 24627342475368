import { APP_V2_APP_EVENTS, isCapacitorPlatform } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import {
  type AcceptableUploadFileContentTypes,
  blobToBase64,
  getFileTypeFromBlob,
  isAcceptableFileUploadContentType,
} from "@src/appV2/lib/files";
import { type ChangeEvent } from "react";

import { type UploadFileData } from "../types";

interface BrowserOnFileChangeProps {
  onSuccess: (file: UploadFileData) => void;
  onError: (error: Error) => void;
}

export function useBrowserOnFileChange(
  props: BrowserOnFileChangeProps
): (event: ChangeEvent<HTMLInputElement>) => Promise<void> {
  const { onSuccess, onError } = props;

  return async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      if (isCapacitorPlatform()) {
        throw new Error("Invalid Platform");
      }

      event.preventDefault();
      const { target } = event;

      if (target?.files === null) {
        return;
      }

      const { files } = target;

      const blob = files[0];
      const dataUrlBase64 = await blobToBase64(blob);
      const { extension, contentType } = await getFileTypeFromBlob(blob);

      if (!isAcceptableFileUploadContentType(contentType)) {
        throw new Error(`File with unsupported content type picked: ${contentType}`);
      }

      onSuccess({
        dataUrlBase64,
        blob,
        extension,
        contentType: contentType as AcceptableUploadFileContentTypes,
      });

      target.value = "";
    } catch (error) {
      logEvent(APP_V2_APP_EVENTS.DOCUMENT_UPLOAD_BROWSER_FILE_PICKER_FAILURE, {
        error,
      });
      onError(error as Error);
    }
  };
}
